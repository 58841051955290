
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup () {
    const model = CloudFun.current?.model;
    const startDateFilter = ref<Date>();
    const endDateFilter = ref<Date>();
    const warehouseFilter = ref<number>();
    const grid = ref<any>({})
    const typeFilter = ref<number>();

    var printColumns = [
      { field: 'Time' },
      { field: 'TypeName' },
      { field: 'Product.Name' },
      { field: 'ImportWarehouse.Name' },
      { field: 'ExportWarehouse.Name' },
      { field: 'Quantity' },
    ]

    const gridOptions: GridOptions = {
      title: '操作紀錄',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: { sheetName: '操作紀錄', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '操作紀錄', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'Time', title: '時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') },
        { field: "Type", title: "類型", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.InventoryRecordType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Product.Name', title: '產品', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ExportWarehouse.Name', title: '出貨倉庫', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ImportWarehouse.Name', title: '進貨倉庫', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Quantity', title: '數量', showHeaderOverflow: true, showOverflow: true, resizable: false },
      ],
      promises: {
        query: model ? (params) => {
          if (!params.condition) params.condition = new Condition();
          if (typeFilter.value !== undefined) params.condition.and("Type", Operator.Equal, typeFilter.value);
          if (startDateFilter.value) params.condition.and("Time", Operator.GreaterThanOrEqual, startDateFilter.value);
          if (endDateFilter.value) {
            const value = typeof endDateFilter.value === 'string' ? new Date(endDateFilter.value) : endDateFilter.value;
            params.condition.and("Time", Operator.LessThanOrEqual, new Date(value.setDate(value.getDate() + 1)));
          }
          if (warehouseFilter.value) params.condition.and(new Condition("ImportWarehouse.Id", Operator.Equal, warehouseFilter.value).or("ExportWarehouse.Id", Operator.Equal, warehouseFilter.value));
          params.sortings = params.sortings || [];
          params.sortings.push({ column: 'Time', order: 1 });
          return model.dispatch('inventoryRecord/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('inventoryRecord/query', { sortings: [{ column: 'Time', order: 1 }] }) : undefined,
      },
      modalConfig: { width: 700 }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        { field: 'Time', title: '時間', span: 12, itemRender: { name: '$input', props: { type: 'datetime', readonly: true } } },
        { field: "Type", title: "類別", span: 12, itemRender: { name: "$select", props: { readonly: true }, options: model ? Object.values(model.enums.InventoryRecordType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Product.Name', title: '產品', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'Quantity', title: '數量', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'ExportWarehouse.Name', title: '出貨倉庫', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'ImportWarehouse.Name', title: '進貨倉庫', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'PurchaseBill.Number', title: '進貨單', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'AdjustInventoryBill.Number', title: '庫存調整單', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'Order.Number', title: '訂單', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
      ]
    }

    const warehouseSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇倉庫',
      textField: 'Name',
      valueField: 'Id',
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, width: 120, sortable: true },
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value: any) => model!.dispatch("warehouse/find", value), // eslint-disable-line
        query: (params: any) => model!.dispatch("warehouse/query", params) // eslint-disable-line
      },
    }

    return {
      grid,
      gridOptions,
      formOptions,
      warehouseSelectOptions,
      startDateFilter,
      endDateFilter,
      warehouseFilter,
      typeFilter,
    }
  },
  methods: {
    onGridRemoveSelectedRows (rows: any, callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
  }
})
